import { useQuery } from '@tanstack/react-query';
import { cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import type {
  CashflowTimeframeRequest,
  CashflowTimeseries as CashflowTimeseriesApiResponse,
} from 'qonto/react/api/models/cash-flow-timeseries';
import { camelizeKeys } from 'qonto/react/api/utils/camelize-keys';
import type { QueryResult } from 'qonto/react/api/utils/query-result';
import type { CashflowTimeseries } from 'qonto/react/models/cash-flow-timeseries';
import { useFetchApi } from './use-fetch-api';

export async function fetchCashflowTimeseries(
  request: CashflowTimeframeRequest,
  fetchApi: ReturnType<typeof useFetchApi>
): Promise<CashflowTimeseries> {
  const endpointUrl = `${cashFlowCategoriesNamespace}/cash-flow/timeseries`;
  const response = await fetchApi(endpointUrl, {
    method: 'POST',
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error('Error fetching cashflow timeseries');
  }

  const data = (await response.json()) as CashflowTimeseriesApiResponse;
  const timeseries = camelizeKeys(data) as CashflowTimeseries;

  return timeseries;
}

export const useCashflowTimeseries = (
  request: CashflowTimeframeRequest
): QueryResult<CashflowTimeseries> => {
  const fetchApi = useFetchApi();

  const {
    data,
    isLoading: isPending,
    isError,
  } = useQuery({
    queryKey: ['cashflow-timeseries'],
    queryFn: () => fetchCashflowTimeseries(request, fetchApi),
    staleTime: 1000 * 60 * 30, // 30 min
    refetchOnWindowFocus: false,
  });

  return {
    data,
    isPending,
    isError,
  };
};
